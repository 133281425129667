@import "../styles/variables";

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

.vertical-timeline::before{
  background-color: $color-grey-light-1;
}
