.currency-flag-img{
    width: 2.5rem;
    /* padding: 1rem; */
    margin-right: 1rem;
}

.back-btn{
    margin-right: 2rem;
}

.outlets-labels{
    color: #777;
    margin-left: 1rem;
}

.outlets-labels-checkbox{
    margin-right: 1rem;
}

.password-change-switch-row{
    margin-bottom: 2rem;
}

.password-change-check{
    margin-right: 1rem !important;
}

.user-email-disabled{
    background: #eee !important;
    color: #555 !important;
  }
