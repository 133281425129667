.sell-history-action-btn-parked{
    font-size: 2rem;
    color: green !important;
}

.sell-history-action-btn-return{
    font-size: 2rem;
    color: blue !important;
}

.sell-history-action-btn-quick-view{
    font-size: 2rem;
    color: green !important;
}