.allOrders {
  .search {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 2rem;
    }
  }

  .omni-sales-table{
    /*height: 100vh;*/   //imp prev ver

  }
}

.omni-sales-orders{

  .search {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 1rem;
    }
  }

  .omni-sales-pagination{
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .entries-dropdown{
    margin-bottom: 4rem;
  }


}


.omni-bulk-sales-orders {
  .cancel-omni-orders{
    background-color: #d9534f !important;
    border-color: #d43f3a !important;
    color: #fff;
    margin-left: 2rem;
  }
}


.omni-sales-orders-view{

  .sale-order-item-content{
    margin-bottom: 1rem;
    color: #999;
  }

  .sale-order-item-content-font{
    font-weight: 700;
  }

  .sale-order-details-heading{
    margin-bottom: 2rem;
    background: #f3f3f3;
    padding: 10px;
    border-radius: 4px;
  }

  .sale-order-products-heading{
    background: #f3f3f3;
    padding: 10px;
    border-radius: 4px;
  }


  .omni-sale-row-heading{
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

}

@media print {
  .ant-layout-sider-children,
  .header,
  .page__header__buttons,
  .back-btn {
      display: none;
  }
}



