// COLORS
// $color-primary: #07b587;
$color-primary: #1eb488;
$color-primary-light: #5ad69e;

$color-secondary: #fcbd00;
$color-secondary-light: #fdd200;
$color-secondary-dark: #f3974c;

$color-asphalt: #34495e;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #333;


$color-logo-dropdown-item: #1890ff;

// FONT
$default-font-size: 1.6rem;
$font-size-big: 3rem;
$font-size-medium: 2.4rem;
$font-size-small: 1.2rem;

$semi-bold: 600;
