@import "../../../styles/variables";


.stock-item-content{
    margin-bottom: 1rem;
    color: #555;
}

.label-stock-count{
    background-color: #5cb85c;
    float: right  !important;
    color: white;
    padding: 0.2rem;
    margin-right: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.stock-receive-details-heading{
    margin-bottom: 2rem;
    background: #f3f3f3;
    padding: 10px;
    border-radius: 4px;
}

.stock-receive-products-heading{
    background: #f3f3f3;
    padding: 10px;
    border-radius: 4px;
}

.stock-receive-row-heading{
    margin-bottom: 2rem;
}

.stock-oder-add-btn{
    margin-top: 3rem;
    width: 100%;
    border: 2px solid #3da446 !important;
}

.bulk-order-switch{
    margin-right: 1rem !important;
}


.stock-control .stock-transfer-row-date-picker{

    justify-content: flex-start !important;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .date-picker {
      margin-right: 2rem;
    }

}


.stock-add .select-product-drop-down-menu {

    border: 2px solid $color-primary !important;
    border-radius: 3px;

      .ant-select-selector{
        border: 0px solid transparent !important;
        box-shadow: none !important;
        border-width: 0px !important;
        outline: none;
      }

}

