
.ecommerce-sale-order{
    /*background-color: #777;*/   //imp prev ver
    color: #fff;
    padding: 0.3rem;
    border-radius: .25em;
}

.ecommerce-sale-order-completed{
  background-color: #5cb85c;
  color: #fff;
  padding: 0.3rem;
  border-radius: .25em;
}

.ecommerce-sale-order-returned{
  background-color: #d9534f;
  color: #fff;
  padding: 0.3rem;
  border-radius: .25em;
}

.ecommerce-sale-order-canceled{
  background-color: #d9534f;
  color: #fff;
  padding: 0.3rem;
  border-radius: .25em;
}

.ecommerce-sale-order-waiting {
  background-color: #f0ad4e;
  color: #fff;
  padding: 0.3rem;
  border-radius: .25em;

}

.omni-sales-order-id{
  color: #337ab7;
  text-decoration: none;
}

.wrap-width-col{
  width: max-content;
}





