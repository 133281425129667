.table-frame {
    .ant-table-row-expand-icon {
      display: none;
    }
    .ant-table-row-expand-icon-cell{
      display: none;
    }
}

.products-listing-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr {
  .product-name-heading{
    width: 20%;
  }
}


.products-editable-cell-value-wrap .product-editable-cell-icon {

  float: right;
  border: 1px solid #1eb488;
  color: #1eb488;
  padding: 0.2rem;

}


.products-lookup-count,
.products-discount-tag{
  background-color: #337ab7;
  color: #fff;
  padding: 0.2rem;
}

.products-lookup-store{
  background-color: #5cb85c;
  color: #fff;
  padding: 0.2rem;
}

.products-zero-discount-tag{
  background-color: #777;
  color: #fff;
  padding: 0.2rem;
}

.select-w-100{
  width: 100%;
}

