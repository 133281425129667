*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

.ant-layout {
  min-height: 100vh;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
  font-weight: 600;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  width: fit-content;
}

.ant-menu-item-active .anticon{
  background: #1eb488;
  color: #fff;
  font-size: 16px;
}

.ant-menu-submenu-active .anticon {
  background: #1eb488;
  color: #fff;
  font-size: 16px;
}

.ant-spin-blur{
  display: unset;
}

.ant-spin-nested-loading > div > .ant-spin{
  display: none;
}



div#preloader { 
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background:#fff !important;
  /* background:#fff url(../images/load2.gif) no-repeat center center !important; */
  /* display: none; */
}
.loader {
  //border: 6px solid #f3f3f4; /* Light grey */
  //border-top: 6px solid #33c097; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  //animation: spin 0.8s linear infinite;
  display: inline-block;  
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  //background-image:  url('../images/shopdesk_logo.svg') no-repeat center center !important;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
div#sub-preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: visible;
  /* background: rgba(231, 231, 231, 0.44) url(../images/load2.gif) no-repeat center center; */
  background: rgba(231, 231, 231, 0.44) ;
  display: none;
  //text-align: center;
}


#app-loader-container {
  position: fixed;
  background: rgba(231, 231, 231, 0.44);
  display: none;
  z-index: 999999;
  width: 100%;
  height: 100%;
  text-align: center;

}

#app-loader-container>img {
  width: 8rem;
  //margin-top: 18%;     //imp prev one
  /*-------new improved version-----------*/
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*---------new verion---------*/
  
}




