// Page
.page {
  background-color: $color-white;
  border-radius: 1rem;
  margin: 2.4rem 1.6rem;
  padding: 2.4rem;
  overflow: auto !important;
  height: calc(100vh - 6.4rem);

  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @include respond(mobile) {
    height: calc(100vh - 12.8rem);
  }

  &__header {
    padding: 0 0 3rem 0;
    border-bottom: 1px solid $color-grey-light-1;
    display: flex;
    justify-content: space-between;

    @include respond(mobile) {
      flex-direction: column;
    }

    h1 {
      @include respond(mobile) {
        margin-bottom: 2rem;
      }
    }

    &__buttons {
      button {
        margin: 0 0.5rem;
      }
    }
  }

  &__content {
    padding: 3rem 0 6rem 0;
  }

  &__form {
    max-width: 100rem;
    margin: 0 auto;

    &--small {
      max-width: 40rem;
    }

    @include respond(mobile) {
      max-width: 100%;
      margin: 0;
    }
  }

  .action-row {
    display: flex;
    justify-content: space-between;

    @include respond(mobile) {
      flex-direction: column-reverse;
    }

    &__element {
      @include respond(mobile) {
        margin-bottom: 2rem;
      }
    }
  }

  .table {
    margin-top: 3rem;
    overflow: auto;

    .action-btns {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    .table-frame {
      min-width: 80rem;
    }
  }
}

// Mobile Menu
.mobile__menu {
  min-width: 25rem;
  position: fixed;
  left: -25rem;
  z-index: 1;
  transition: all 0.3s;
}

.mob_menu_on {
  height: 100%;
  left: 0;
  overflow: scroll;
}

.mobile_menu_on_body {
  transform: translateX(25rem);
}

// Form
.form {
  &__section {
    padding: 3rem 0;
    border-bottom: 1px solid $color-grey-light-1;

    &__header {
      &--btn {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__row {
    display: flex;

    @include respond(mobile) {
      flex-direction: column;
    }

    &--full-col {
      flex-direction: column;
    }

    &--footer {
      display: flex;
      justify-content: flex-end;

      button {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__col {
    flex: 1;

    &--button {
      display: flex;
      align-items: flex-end;

      button {
        width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 2rem;

      @include respond(mobile) {
        margin: 0;
      }
    }

    label {
      font-weight: bold;
    }

    &--btn {
      display: flex;

      .custom-btn {
        height: auto;
        margin-left: 2rem;
      }
    }
  }
}

// Buttons
.custom-btn {
  &--primary {
    background: $color-primary !important;
    border-color: $color-primary !important;

    &:hover {
      background-color: $color-primary-light !important;
      border-color: $color-primary-light !important;
    }
  }
}
