@import "../../../styles/variables";


.loading-container {
  text-align: center;
}

.products-variants-attribute-key-disabled {
  background: #eee !important;
}
.thumbnail {
  display: block;
  padding: 4px;
  width: 200px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.inclusive-sale-price-check {
  float: right;
}

.product-sale-price-margin {
  margin-top: -2rem !important;
}

.product-delete-sku-highlight {
  color: #a94442 !important;
}

.fetch-product-row {
  display: flex;
  justify-content: flex-end;
}

.fetch-product-row button {
  height: 35px;
}

.fetch-product-btn:hover {
  background-color: #e6e6e6 !important;
}

.variants-heading,
.lookup-heading {
  margin-bottom: 2rem;
  margin-top: 2rem;
}


.product-look-up .select-product-drop-down-menu {

  border: 3px solid $color-primary !important;
  border-radius: 3px;

    .ant-select-selector{
      border: 0px solid transparent !important;
      box-shadow: none !important;
      border-width: 0px !important;
      outline: none;
    }

}


