@mixin respond($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}

// Extends
%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
