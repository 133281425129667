@import "../../../styles/variables";
@import "../../../styles/mixins";

.dashboard {
  .page__section {
    padding: 4rem 0;
    border-bottom: 1px solid $color-grey-light-1;
  }

  .charts {
    display: flex;

    @include respond(mobile) {
      flex-direction: column;
    }

    .sales {
      @include respond(mobile) {
        margin-bottom: 4rem;
      }
    }
  }

  .sales {
    flex: 1;
  }

  .items {
    flex: 2;
  }

  .section__heading {
    margin-bottom: 4rem;
  }

  /*--info cards-dashboard--*/

  .info {
    border: 2px solid #eee;
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: center;
  }

  .data-info {
    border: 1px solid #000 !important;
    border-radius: 8px;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
  }

  .info .data {
    font-weight: 100 !important;
    padding: 10px 20px;
  }

  .data-info .data {
    padding: 50px 20px !important;
  }

  .info .row {
    border-top: 2px solid #eee;
    font-size: 18px;
    padding: 8px;
    color: #777;
  }
  .info .row {
    border-top: 1px solid #000 !important;
  }

  /*--info cards-dashboard--*/

  // Info Cards
  .info-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .info-card {
    text-align: center;
    box-shadow: 0px 0px 20px 0px $color-black;
    border-radius: 1rem;
    padding: 4rem;
    margin: 2rem;
    flex: 1;

    &__value {
      margin: 2rem 0;
      font-size: $font-size-big;
      font-weight: bold;
    }
  }
}
